<template>
  <div class="notice">
    <div class="titleTop">
      <img @click="goBack()" src="../assets/back.png" alt="" />
      <h1>公告</h1>
    </div>
    <div class="noticeCon">
      <div class="noticeList">
        <div
          class="noticeItem"
          @click="goDetail(index)"
          v-for="(item, index) in noticeData"
          :key="item.id"
        >
          <div class="itemTop">
            <h1>{{ item.title }}</h1>
            <p>{{ item.create_time }}</p>
            <p>{{ item.desc }}</p>
          </div>
          <div class="line"></div>
          <div class="ckxq">
            <div>查看详情</div>
            <img src="../assets/next.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNotice } from "../api/index.js";

export default {
  setup() {},
  data() {
    return {
      noticeData: [],
    };
  },
  mounted() {
    this.getNoticeData();
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    goDetail(index) {
      this.$router.push(`/noticeDetail?index=${index}`);
    },
    getNoticeData() {
      let params = {};
      getNotice(params).then((res) => {
        this.noticeData = res.data;
        console.log(this.noticeData);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.notice {
  .titleTop {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #fff;
    img {
      width: 16px;
      position: absolute;
      left: 18px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    h1 {
      line-height: 40px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
  }
  .noticeCon {
    padding: 15px;
    .noticeItem {
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 0 15px;
      .itemTop {
        padding-top: 15px;
        min-height: 35px;
        h1 {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
        }
        p {
          font-size: 12px;
          color: #999999;
          margin-top: 5px;
        }
      }

      .line {
        height: 1px;
        background-color: #e4e4e4;
        margin-top: 10px;
      }
      .ckxq {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        div {
          font-size: 12px;
          color: #999999;
        }
        img {
          width: 6px;
        }
      }
    }
  }
}
</style>